<template>
  <div class="horiz-box">
    <h1 id="subtitle"> Custom Light Art</h1>
  </div>
  <!-- <div class="horiz-box" id="lumatron-header">
    <div class="horiz-box" id="ravenstear-hero">
      <iframe id="hero" src="https://my.lumatron.art/demo/view?bgopacity=0.021&disablewheel&cameraz=12&controller=none" frameBorder="0"></iframe>
      <img src="@/assets/ravenstear-overlay-top.svg" class="emulator-overlay top">
      <img src="@/assets/ravenstear-overlay-walls.svg" class="emulator-overlay walls">
      <div class="border-smoother"></div>
    </div>
    <h1 id="tag-line">
      Custom <br>
      Light <br>
      Art
    </h1>
  </div> -->
  <div class="horiz-box tall"></div>
  <div class="horiz-box">
    <div class="carousel">
      <video autoplay loop muted playsinline preload="metadata"
          v-for="video in videos"
          :poster="getAssetUrl(thumbnailNameFromVideo(video))"
          class="catalogue-item">
        <source :src="getAssetUrl(video)" type="video/mp4">
      </video>

      <div class="carousel-dots">
        <div class="dot"
            v-for="i in carouselSize"
            @click="carouselTarget = i-1; updateCarousel()"
        >
          <div class="inner"
            :class="{selected: carouselTarget == i-1}">
          </div>
        </div>
      </div>
      <div class="control left" @click="carouselTarget -= 1; updateCarousel()"></div>
      <div class="control right" @click="carouselTarget += 1; updateCarousel()"></div>
    </div>
  </div>
  <div class="horiz-box tall"></div>
  <div class="horiz-box">
    <h1>Controllable</h1>
  </div>
  <div class="horiz-box">
    <h2>Try it out!</h2>
  </div>
  <div class="horiz-box" id="demo-wrapper">
    <div>
      <iframe id="demo" src="https://my.lumatron.art/demo/view?bgopacity=0.021&disablewheel&cameraz=12&controller=none" frameBorder="0"></iframe>
      <img src="@/assets/ravenstear-overlay-top.svg" class="emulator-overlay top">
      <img src="@/assets/ravenstear-overlay-walls.svg" class="emulator-overlay walls">
    </div>
    <div class="border-smoother"></div>
  </div>
  <div class="horiz-box controller-wrapper">
    <div class="horiz-box positioner">
      <div id="controller-qr">
        Scan to control with another device
        <img src="@/assets/controller-qrcode.svg">
      </div>
    </div>
    <div class="horiz-box positioner">
      <div id="controller">
        <iframe  src="https://my.lumatron.art/demo" frameBorder="0"></iframe>
        <div class="outline"></div>
      </div>
    </div>
  </div>
  <div class="horiz-box tall"></div>
  <div class="horiz-box">
    <h1>Features</h1>
  </div>
  <div id="features">
    <div v-for="feature in features" class="feature">
      <div class="inner">
        <div v-if="feature.optional" class="optional">*</div>
        <div class="icon">
          <img :src="getAssetUrl(feature.icon)" />
        </div>
        <div class="copy">{{ feature.copy }}</div>
      </div>
    </div>
    <div v-for="i in 2 - (features.length+2) % 3" class="feature blank wide">
      <div class="inner">
        <div class="icon">
          <img src="@/assets/colors.svg"/>
        </div>
        <div class="copy"></div>
      </div>
    </div>
    <div v-for="i in 1 - (features.length+1) % 2" class="feature blank narrow">
      <div class="inner">
        <div class="icon">
          <img src="@/assets/colors.svg"/>
        </div>
        <div class="copy"></div>
      </div>
    </div>

    <div class="border"></div>
  </div>
  <div class="horiz-box section" style="justify-content: flex-end;">*optional</div>
  <div class="horiz-box tall"></div>
  <div class="horiz-box">
    <h1>How it's made</h1>
  </div>
  <div class="horiz-box"></div>
  <div id="process" :style="{ zoom: processZoom }">
    <div class="row">
      <div class="process-step left">
        <video autoplay loop muted playsinline
            :poster="getAssetUrl('process-design.jpg')">
          <source :src="getAssetUrl('process-design.mp4')" type="video/mp4">
        </video>
        <div class="text">Design</div>
        <div class="down-arrow left empty"></div>
      </div>
      <div class="side-arrow">
        <Icon imagePrefix='/images/arrow' :size="128"/>
      </div>
      <div class="process-step right">
        <video autoplay loop muted playsinline
            :poster="getAssetUrl('process-emulation.jpg')">
          <source :src="getAssetUrl('process-emulation.mp4')" type="video/mp4">
        </video>
        <div class="text">Emulation</div>
        <div class="down-arrow right">
          <Icon imagePrefix='/images/arrow' :size="128"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="process-step left">
        <img :src="getAssetUrl('process-cut-acrylic.jpg')">
        <div class="text">Laser Cutting</div>
        <div class="down-arrow left">
          <Icon imagePrefix='/images/arrow' :size="128"/>
        </div>
      </div>
      <div class="side-arrow left">
        <Icon imagePrefix='/images/arrow' :size="128"/>
      </div>
      <div class="process-step right">
        <img :src="getAssetUrl('process-materials.jpg')">
        <div class="text">Materials</div>
        <div class="down-arrow right">
          <Icon imagePrefix='/images/arrow' :size="128"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="process-step left">
        <img :src="getAssetUrl('process-assembled.jpg')">
        <div class="text">Assembly</div>
        <div class="down-arrow left">
          <Icon imagePrefix='/images/arrow' :size="128"/>
        </div>
      </div>
      <div class="side-arrow left">
        <Icon imagePrefix='/images/arrow' :size="128"/>
      </div>
      <div class="process-step right">
        <img :src="getAssetUrl('process-walls.jpg')">
        <div class="text">3D Printing</div>
        <div class="down-arrow right empty"></div>
      </div>
    </div>

    <div class="row">
      <div class="process-step left">
        <video autoplay loop muted playsinline
            :poster="getAssetUrl('process-leds.jpg')">
          <source :src="getAssetUrl('process-leds.mp4')" type="video/mp4">
        </video>
        <div class="text">LED Testing</div>
        <div class="down-arrow left">
          <Icon imagePrefix='/images/arrow' :size="128"/>
        </div>
      </div>
      <div class="side-arrow left">
        <Icon imagePrefix='/images/arrow' :size="128"/>
      </div>
      <div class="process-step right">
        <img :src="getAssetUrl('process-box.jpg')">
        <div class="text">Control Box</div>
        <div class="down-arrow right empty"></div>
      </div>
    </div>

    <div class="row">
      <div class="process-step left">
        <video autoplay loop muted playsinline
            :poster="getAssetUrl('process-final.jpg')">
          <source :src="getAssetUrl('process-final.mp4')" type="video/mp4">
        </video>
        <div class="text">Final Assembly</div>
        <div class="down-arrow left empty"></div>
      </div>
      <div class="side-arrow">
        <Icon imagePrefix='/images/arrow' :size="128"/>
      </div>
      <div class="process-step right">
        <video autoplay loop muted playsinline
            :poster="getAssetUrl('process-installation.jpg')">
          <source :src="getAssetUrl('process-installation.mp4')" type="video/mp4">
        </video>
        <div class="text">Installation</div>
        <div class="down-arrow right empty"></div>
      </div>
      <!-- <div class="process-step right empty"></div> -->
    </div>
  </div>

  <div class="horiz-box tall"></div>
  <div class="horiz-box">
    <h1>Want one?</h1>
  </div>
  <div class="horiz-box"></div>

  <div class="fancy-box section">
    <p>
      Every Lumatron is guaranteed unique and I work with every client to design a custom piece that fits their preferences.
    </p>
    <p>
      For inquiries and quotes, email <a href="mailto:adrian@marplebot.com">adrian@marplebot.com</a>
    </p>
  </div>
  <div class="horiz-box xtall"></div>

  <div class="horiz-box">
    <h1>About me</h1>
  </div>
  <div class="fancy-box section">
    <img class="portrait" src="@/assets/Adrian.jpg">
    <p>
      I am a bay area artist whose medium more than anything is systems.
      With a background in math and computer science, I've undertaken a wandering journey
      making proofs, apps, chainmail, lighting, games, and myriad other creative endeavors.
      Even though I arose from the milieu of the purely digital and infinitely scalable,
      I've developed a magnetic attraction to that which can’t be perfectly captured in 1s and 0s
      and has a personal touch rather than mass reproducibility.
    </p>
    <p>
      You can see many of my other projects on <a href="https://marplebot.com">my main website</a>,
      and see slightly more up to date pictures on my <a href="https://www.instagram.com/adrianmarple">instagram</a>.
    </p>
  </div>
  <div class="horiz-box"></div>
  <div class="fancy-box section">
    <img class="portrait right" src="@/assets/mana-avatar.jpeg">
    <p>
      Lumatron has also had extensive help from manadream (aka mana).
      They've helped largely with networking and other low level programming tasks,
      but have really contributed throughout in various ways.
      Check out <a href="https://linktr.ee/manadream">https://linktr.ee/manadream</a> to see other stuff they've made.
    </p>
  </div>

  <div class="horiz-box tall"></div>

  <div class="horiz-box">
    <h1>Want more?</h1>
  </div>
  <div class="horiz-box"></div>
  <Signup/>
</template>
FancyButton
<script>
import Signup from "@/components/Signup"
import FancyButton from "@/components/FancyButton"
import Icon from "@/components/Icon"

export default {
  name: 'Home',
  components: { Signup, FancyButton, Icon },
  data() {
    return  {
      innerWidth,
      startX: 0,
      carouselInterval: null,
      carouselPosition: 0,
      carouselTarget: 0,
      carouselVelocity: 0,
      videos: [
        "video-mirror-loop.mp4",
        "video-flower-loop.mp4",
        "video-sierpinski-loop.mp4",
        "video-shower-loop.mp4",
      ],
      features: [
        {
          icon: "qr.svg",
          copy: "Scan QR code to control",
        },
        {
          icon: "wifi.svg",
          copy: "WiFi connected",
        },
        {
          icon: "colors.svg",
          copy: "Select colors",
        },
        {
          icon: "pattern.svg",
          copy: "Choose from various patterns",
        },
        {
          icon: "night.svg",
          copy: "Can automatically turn off at night",
        },
        {
          icon: "save.svg",
          copy: "Save preferences",
        },
        {
          icon: "games.svg",
          copy: "Games",
        },
        {
          icon: "download.svg",
          copy: "Automatically gets software updates",
        },
        {
          icon: "14-segment.png",
          copy: "4-charater 14 segment display",
        },
        {
          icon: "thunderstorm.svg",
          copy: "Pattern based on weather",
          optional: true,
        },
        {
          icon: "mic.svg",
          copy: "Music responsive",
          optional: true,
        },
        {
          icon: "lock.svg",
          copy: "Security code",
          optional: true,
        },
        {
          icon: "egg.svg",
          copy: "Easter eggs (ask me)",
          optional: true,
        },
        {
          icon: "twitch.png",
          copy: "Twitch integration (upcoming)",
          optional: true,
        },
        {
          icon: "question_exchange.svg",
          copy: "Request special features",
          optional: true,
        },
      ]
    }
  },
  mounted() {
    // const heroFrame = document.getElementById('hero')
    // heroFrame.onload = () => {
    //   setTimeout(() => {
    //     heroFrame.style.opacity = 1
    //   }, 500)
    // }
    this.updateCarousel()

    
    onmousedown = this.handleStart
    onmouseup = this.handleEnd
    onmousemove = this.handleChange

    let self = this
    if (window.autoAdvanceInterval) { // In case this has been remounted
      clearInterval(window.autoAdvanceInterval)
    }
    window.autoAdvanceInterval = setInterval(() => {
      self.carouselTarget = (self.carouselTarget + 1) % self.videos.length
      self.updateCarousel(true)
    }, 5 * 1000)

    addEventListener('resize', (event) => {
      self.innerWidth = innerWidth
      self.$forceUpdate()
    })
  },
  computed: {
    carouselSize() {
      return this.videos.length
    },
    processZoom() {
      return Math.min((this.innerWidth * 0.95) / 640, 1)
    },
  },

  methods: {
    getAssetUrl(name) {
      if (!name) return
      return require("@/assets/" + name)
    },
    thumbnailNameFromVideo(name) {
      return name.replace("video", "thumbnail").replace("-loop.mp4", ".jpg")
    },

    advanceCarousel() {
      this.carouselPosition += 1
      this.updateCarousel()
    },
    updateCarousel(keepAutoadvance) {
      if (!keepAutoadvance) {
        clearInterval(window.autoAdvanceInterval)
        window.autoAdvanceInterval = null
      }
      if (this.carouselInterval) {
        return
      }
      this.carouselInterval = setInterval(() => {
        this.carouselTarget = Math.max(0, this.carouselTarget)
        this.carouselTarget = Math.min(this.videos.length - 1, this.carouselTarget)

        let motionBlur = 0
        if (!this.isDragging) {
          const alpha = 0.7
          this.carouselVelocity = alpha * this.carouselVelocity
          let startingPosition = this.carouselPosition
          this.carouselPosition += this.carouselVelocity
          this.carouselPosition = alpha * this.carouselPosition + (1 - alpha) * this.carouselTarget
          let delta = this.carouselPosition - startingPosition
          let maxSpeed = 0.4
          if (Math.abs(delta) > maxSpeed) {
            delta = maxSpeed * Math.sign(delta)
            this.carouselPosition = startingPosition + delta
          }
          motionBlur = Math.abs(delta)*2
        }
        let slides = document.querySelectorAll(".carousel video")
        for (let i = 0; i < slides.length; i++) {
          let slide = slides[i]
          let offset = i - this.carouselPosition
          slide.style.transform = `
              translateX(${Math.sign(offset)*Math.pow(Math.abs(offset), 0.5)*50}%)
              scale(${1/(Math.abs(offset)/2 + 1)})`
          slide.style.zIndex = Math.round(100 - Math.abs(offset*2))
          let opacity = 1/Math.pow(Math.abs(offset) + 1,2)
          opacity = Math.min(opacity, 1 - motionBlur)
          slide.style.opacity = opacity
        }
        
        this.$forceUpdate()
        if (Math.abs(this.carouselPosition - this.carouselTarget) < 0.0001) {
          clearInterval(this.carouselInterval)
          this.carouselInterval = null
        }
      }, 33)
    },

    handleStart(location) {
      let carouselPosition = document.querySelector(".carousel").getBoundingClientRect()
      if (carouselPosition.top > location.clientY ||
          carouselPosition.bottom < location.clientY) {
        return
      }
      this.startX = location.clientX
      this.timestamp = Date.now()
      this.isDragging = true
    },
    handleEnd() {
      if (this.isDragging) {
        let finalPosition = this.carouselPosition + this.carouselVelocity * 20
        this.carouselTarget = Math.round(finalPosition)
        this.carouselTarget = Math.max(this.carouselTarget, 0)
        this.carouselTarget = Math.min(this.carouselTarget, this.videos.length - 1)
        this.updateCarousel()
        this.isDragging = false
      }
    },
    handleChange(location) {
      if (this.isDragging) {
        let delta = (this.startX - location.clientX) / 450
        this.carouselPosition += delta
        this.carouselVelocity = Math.max(Math.min(delta,0.2),-0.2)
        this.startX = location.clientX
        this.updateCarousel()
      }
    },
  },
}
</script>

<style>
.section {
  padding: 0 24px;
  box-sizing: border-box;
  font-size: 2.5em;
}
.section a {
  text-decoration: underline;
}

.large-horiz-video {
  width: 100%;
}

#subtitle {
  /* font-size: 3rem; */
}
#lumatron-header {
  /* justify-content: flex-start; */
  position: relative;
}


#ravenstear-hero {
  position: relative;
  width: 420px;
  height: 420px;
  max-height: 100vw;
}
#hero {
  opacity: 0.01;
}

.video-color-corrector {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.004;
  background: white;
  display: none;
}
.border-smoother {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(circle at center, rgba(8,8,8,0) 65%, #080808 71%);
}
#tag-line {
  position: absolute;
  left: 0;
  top: 0;
  text-align: left;
}

.carousel {
  display: flex;
  justify-content: center;
  position: relative;
  height: 800px;
  max-height: 177vw;
  width: 100%;
  max-width: 450px;
}
.catalogue-item {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 12px rgba(255,255,255, 0.7), 0 0 8px rgba(255,255,255, 0.7), 0 0 4px rgba(255,255,255, 0.7);
  z-index: 1;
  position: absolute;
  border-radius: 12px;
}


.slide {
  width: 100%;
  padding:  0 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carousel-dots {
  display: flex;
  position: absolute;
  z-index: 112;
  bottom: 0;
}
.carousel-dots .dot {
  padding: 1rem;
  margin:  0.5rem;
}
.carousel-dots .dot .inner {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: white;
  opacity: 0.5;
  box-shadow: 0 0 4px black;
  /* box-shadow: 0 0 8px white; */
}
.carousel-dots .dot .inner.selected {
  opacity: 1;
  box-shadow: 0 0 8px white, 0 0 12px white;
}

.carousel .control {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: 111;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position-y: center;
  opacity: 0;
}
.carousel .control.left {
  left: 0;
  background-image: url("@/assets/chevronleft.svg");
  background-position-x: left;
}
.carousel .control.right {
  right: 0;
  background-image: url("@/assets/chevronright.svg");
  background-position-x: right;
}

#features {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
}
#features .border {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid var(--bg-color);
  box-sizing: border-box;
}
@media only screen and (max-device-width: 480px) {
  #features {
    grid-template-columns: repeat(2, 1fr);
  }
}
.feature {
  border: 8px solid var(--bg-color);
}
.feature .inner {
  margin: 2px;
  background-color: var(--bg-color);
  position: relative;
}
.feature .inner .optional {
  position: absolute;
  right: 6px;
  top: 0;
  font-size: 2rem;
  z-index: 1;
}
.feature .inner .icon {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 36px solid var(--bg-color);
  border-bottom-width: 0;
}
.feature .inner .icon img {
  width: 100%;
  filter: invert(100%) drop-shadow(white 0 0 5px);
}
.feature .inner .copy {
  height: 25%;
  font-size: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  height: 80px;
  padding: 0 8px;
}

.feature.blank.narrow {
  display: none;
}
.feature.blank .inner {
  margin: 0;
  padding: 2px;
}
.feature.blank .inner img {
  opacity: 0;
}
@media only screen and (max-device-width: 480px) {
  .feature.blank.wide {
    display: none;
  }
  .feature.blank.narrow {
    display: block;
  }
}

#demo, #ravenstear-hero iframe {
  filter: brightness(1.5);
  width: 100%;
}

#demo-wrapper {
  position: relative;
}
#demo-wrapper > div:first-child {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
#demo {
  width: 100%;
  height: 700px;
  max-height: 100vw;
}
.emulator-overlay {
  position: absolute;
  width: 93.2%;
  top: 3.3%;
}
/* sierpinski version
.emulator-overlay {
  position: absolute;
  width: 78%;
  top: 15.2%;
} */
.emulator-overlay.top {
  opacity: 0.3;
  filter:drop-shadow(white 0 0 12px);
}
.emulator-overlay.walls {
  opacity: 0.7;
}

#controller {
  width: 250px;
  max-width: 100vw;
  height: 450px;
  position: absolute;
  right: -200px;
  bottom: 40px;
}
#controller iframe {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 8px 16px 8px 10px;
  border-radius: 36px;
}
#controller .outline {
  position: absolute;
  background-image: url("@/assets/smartphone.svg");
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert();
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;
}
#controller-qr {
  position: absolute;
  left: -180px;
  bottom: 200px;
  width: 200px;
  max-width: 100vw;
  text-align: center;
  font-size: 1rem;
}
#controller-qr img {
  background-image: url("@/assets/controller-qrcode.svg");
  background-size: contain;
  filter: invert();
  opacity: 0.8;
  width: 100%;
  margin-top: 20px;
}

@media (max-device-width: 1050px) {
  #controller {
    position: relative;
    width: 350px;
    max-width: 100vw;
    height: 640px;
    right: -3px;
    margin-top: 48px;
  }
  #controller iframe {
    border-radius: 60px;
  }

  #controller-qr {
    position: relative;
    bottom: initial;
    left: initial;

  }
}
@media (max-device-width: 600px) {
  .horiz-box.controller-wrapper {
    flex-direction: column-reverse;
  }
}

.portrait {
  width: 200px !important;
  height: 200px;
  border-radius: 50%;
  margin: 24px 0;
  float: left;
}

.portrait.right {
  width: 200px !important;
  height: 200px;
  border-radius: 50%;
  margin: 24px 0;
  float: right;
}

.process-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  border: 4px solid var(--bg-color);
  margin: -4px;
}
.process-step.empty {
  width: 384px;
  height: 447px;
  background-color: var(--bg-color);
}
.process-step.left {
  border-left-width: 100px;
  margin-left: -100px;
}
.process-step.right {
  border-right-width: 100px;
  margin-right: -100px;
}
.process-step > .text {
  filter: drop-shadow(white 0 0 5px);
}
.process-step video, .process-step img {
  width: 256px;
  height: 256px;
  margin-bottom: 15px;
  border: 1px solid white;
  background-color: var(--bg-color);
  filter: drop-shadow(white 0 0 5px);
}

#process .icon {
  border: 80px solid var(--bg-color);
  margin: -80px;
}
#process .side-arrow {
  z-index: -1;
  position: relative;
}
#process .side-arrow.left {
  transform: rotateY(180deg);
}
#process .side-arrow .icon {
  border-bottom-width: 256px;
}

.down-arrow {
  transform: rotate(90deg);
  z-index: -1;
  width: 128px;
  height: 128px;
  position: relative;
}
/* Necessary for firebox display issues. */
.side-arrow::before, .down-arrow::before {
  content: "";
  position: absolute;
  left: 0.5rem;
  right: 0.5rem;
  top: 0.5rem;
  height: calc(128px - 1rem);
  border: 80px solid var(--bg-color);
  margin: -80px;
}
.down-arrow.empty {
  border: 80px solid var(--bg-color);
  margin: -80px;
  background-color: var(--bg-color);
}

@media only screen and (max-device-width: 480px) {
  .portrait {
    padding: 0 calc(50% - 100px) !important;
    float: none !important;
  }

  #lumatron-header {
    overflow: hidden;
  }
  #ravenstear-hero {
    margin-top: 64px;
    margin-left: 100px;
    margin-right: -100px;
    background: black;
    overflow: hidden;
  }

  .carousel .control {
    opacity: 0.5;
  }
}


</style>
