<template>

<div id="signup" class="fancy-box">
  <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
  <div id="mc_embed_signup">
  <form action="https://marplebot.us19.list-manage.com/subscribe/post?u=94eb26fb510a67c9582cfe935&amp;id=235d9f85c5" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
      <div style="display:flex; justify-content: space-between; align-items: center; margin-right:3.5%">
        <h2>{{message}}</h2>
        <!-- <img style="width:128px; border-radius: 50%;" id="destructor" src="images/Destructor.jpg"> -->
      </div>
      <div class="mc-field-group">
        <label for="mce-EMAIL">Email Address (required)
      </label>
        <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
      </div>
      <div class="mc-field-group">
        <label for="mce-NAME">First Name </label>
        <input type="text" value="" name="NAME" class="" id="mce-NAME">
      </div>
      <div id="mce-responses" class="clear">
        <div class="response" id="mce-error-response" style="display:none"></div>
        <div class="response" id="mce-success-response" style="display:none"></div>
      </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
      <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_94eb26fb510a67c9582cfe935_235d9f85c5" tabindex="-1" value=""></div>
      <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
  </form>
  </div>
</div>
</template>

<script>

export default {
  name: 'Signup',
  props: {
    message: {
      type: String,
      default: "Sign up to get pleasantly infrequent updates about Lumatron and everything else I'm working on.",
    }
  },
  mounted() {

    let signup = document.getElementById("signup")
    signup.innerHTML += "<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'><\/script>"
    signup.innerHTML += "<script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='NAME';ftypes[1]='text';}(jQuery));var $mcj = jQuery.noConflict(true);<\/script>"
  },
}
</script>

<style>
#signup {
  font-size: 14px;
}
#mc_embed_signup {
  width: 100%;
}
.clear {
  display: flex;
  justify-content: center;
  width: 97.5%;
}
#mc-embedded-subscribe.button {
  background-color: white !important;
  margin-right: 0 !important;
  color: #121212 !important;
  font-weight: bold;
}
.response {
  text-align: center !important;
  padding-right: 5% !important;
  color: white !important;
}
</style>
