<template>
  <div class="fancy-button-wrapper">
    <div class="filler"></div>
    <a :href="href">
    <div class="button">
      {{ copy }}
      <div class="shadow"></div>
    </div>
    </a>
    <div class="filler"></div>
  </div>
</template>

<script>
  
export default {
  name: "FancyButton",
  props: {
    href: String,
    copy: String,
  },
}
</script>

<style>

.fancy-button-wrapper {
  display: flex;
}

.filler {
  width: 250px;
  height: 64px;
  background-color: var(--bg-color);
}

.fancy-button-wrapper .button {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 64px;
  font-size: 4em;
  box-shadow: 0 2px 8px white;
  margin: -4px;
  border: 4px solid var(--bg-color);
  border-radius: 8px;
}

/* .fancy-button-wrapper .button .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 12px white;
  border-radius: 4px;
} */

.button:hover {
  background-color: rgba(255,255,255, 0.2);
  border-color: #222;
}

</style>