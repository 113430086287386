<template>
  <a :href="href">
  <div class="icon" :style="sty">
    <svg :width="sz" :height="sz">
      <mask :id="imagePrefix" x="0" y="0" :width="sz" :height="sz">
        <rect x=0 y=0 :width="sz" :height="sz" fill="white"></rect>
        <image x=12 y=12 :width="innerSz" :height="innerSz" :href="imagePrefix+'_black.svg'"></image>
      </mask>
      <rect class="main-rect"
            x=-1 y=-1
            :width="sz+2" :height="sz+2"
            :mask="`url(#${imagePrefix})`"
            fill="var(--bg-color)">
      </rect>

      <image v-if="href" x=12 y=12 :width="innerSz" :height="innerSz" :href="imagePrefix+'_white.svg'" fill="white"></image>
    </svg>
    <div class="extra-border"></div>
  </div>
  </a>
</template>

<script>
  
export default {
  name: "Icon",
  props: {
    href: String,
    imagePrefix: String,
    size: Number,
  },
  computed: {
    sz() {
      return this.size ?? 64
    },
    innerSz() {
      return this.sz - 24
    },
    sty() {
      return {
        width: this.sz + "px",
        height: this.sz + "px",
      }
    },
  }
}
</script>

<style>

.icon {
  position: relative;
}

svg > image {
  opacity: 0;
  transition: opacity .5s cubic-bezier(.08,.59,.29,.99),;
}

.icon:hover svg > image  {
  opacity: 0.5;
}

.extra-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid var(--bg-color);
  box-sizing: border-box;
}

</style>